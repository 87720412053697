import React from "react";
import { IMAGE_BASE_URL, IMAGE_VERSION } from "../../Common/constants";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Pagination, Autoplay } from "swiper";

const bannerArr = [
  {
    bannerWeb: "hb1.jpg",
    bannerMobile: "hb1-mob.jpg",
    bannerLink: "",
  },
  {
    bannerWeb: "hb2.jpg",
    bannerMobile: "hb2-mob.jpg",
    bannerLink: "",
  },
  {
    bannerWeb: "hb3.jpg",
    bannerMobile: "hb3-mob.jpg",
    bannerLink: "",
  },
  {
    bannerWeb: "hb4.jpg",
    bannerMobile: "hb4-mob.jpg",
    bannerLink: "",
  },
];
const HeroBanner = () => {
  return (
    <>
      <div className="hero__wrap">
        <div className="hero__swiper">
          <Swiper
            pagination={{ clickable: false }}
            modules={[Pagination, Autoplay]}
            slidesPerView={1}
            spaceBetween={16}
            loop={true}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            className="heroBannerSwiper"
          >
            {bannerArr.map((item, index) => (
              <SwiperSlide>
                <div className="hero__thumb" key={index}>
                  <a href={item.bannerLink} rel="noopener noreferrer nofollow">
                    <picture>
                      <source
                        media="(min-width:768px)"
                        srcSet={`${`${IMAGE_BASE_URL}/banner/${item.bannerWeb}?v=${IMAGE_VERSION}`} `}
                        alt={item.bannerName}
                        loading="lazy"
                      />
                      <img
                        src={`${`${IMAGE_BASE_URL}/banner/${item.bannerMobile}?v=${IMAGE_VERSION}`} `}
                        alt={item.bannerName}
                        loading="lazy"
                      />
                    </picture>
                  </a>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export { HeroBanner };
