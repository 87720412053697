import React from "react";
import { IMAGE_BASE_URL, IMAGE_VERSION } from "../../Common/constants";

function getClassName(type) {
  switch (type) {
    case "gold":
      return "gold";
    case "diamond":
      return "diamond";
    case "platinum":
      return "platinum";
    default:
      return null;
  }
}

const Card = ({ cardType, cardTitle, cardCaption, cardThumb }) => {
  return (
    <>
      <div className={`card__box card__box--${getClassName(cardType)}`}>
        <div className="card__logo">
          <img
            src={`${IMAGE_BASE_URL}/ss-logo.png?v=${IMAGE_VERSION}`}
            alt="smiling souls logo"
            loading="lazy"
          />
        </div>
        <div className="card__reward">
          <img
            src={`${IMAGE_BASE_URL}/cards/${cardThumb}?v=${IMAGE_VERSION}`}
            alt=""
            loading="lazy"
          />
        </div>
        <div className="card__band">
          {cardCaption && (
            <div
              className="card__band-caption"
              dangerouslySetInnerHTML={{
                __html: cardCaption,
              }}
            ></div>
          )}
          <div
            className="card__band-title"
            dangerouslySetInnerHTML={{
              __html: cardTitle,
            }}
          ></div>
        </div>
      </div>
    </>
  );
};

export { Card };
