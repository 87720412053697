import React from "react";

const ServiceCard = ({
  serviceCardTitle,
  serviceCardCaption,
  serviceCardIcon,
  serviceLink,
}) => {
  return (
    <>
      <a
        href={serviceLink}
        target="_blank"
        rel="noreferrer"
        className="service__card-box"
      >
        <div className="service__card-icon">
          <i className={serviceCardIcon}></i>
        </div>
        <h3 className="service__card-title">{serviceCardTitle}</h3>
        <div className="service__card-caption">{serviceCardCaption}</div>
      </a>
    </>
  );
};

export { ServiceCard };
