import React from "react";
const foo = `javascript:void(0)`;
const Tabs = ({ tabConfig, updateActiveTab = () => {}, activeTab }) => {
  return (
    <>
      <div className="tab">
        <ul>
          {tabConfig.map((item, index) => (
            <li key={index}>
              <div
                id={item.title.toLowerCase()}
                href={foo}
                onClick={() => updateActiveTab(item.key)}
                className={`${item.key === activeTab ? "active" : ""}`}
              >
                {item.title}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export { Tabs };
