import React from "react";

const AccordionChild = ({
  accordionConfig,
  headComponentLHS,
  bodyComponent,
  index,
  isActive,
  updateActiveIndex,
  innerRef = null,
}) => {
  const { showAccordLHS = true, showAccordArrow = true } = accordionConfig;

  const handleOpen = () => updateActiveIndex(index, !isActive);

  return (
    <>
      <div className="accordion__box" ref={innerRef}>
        <button
          type="button"
          aria-expanded={isActive}
          className="accordion__trigger"
          id={`accordion${index}`}
          onClick={handleOpen}
        >
          {headComponentLHS}

          {showAccordArrow && (
            <div className="accordion__icon">
              <i className="tssi-arrow-down"></i>
            </div>
          )}
        </button>
        {isActive && (
          <div
            id={`section${index}`}
            className="accordion__body"
            hidden={!isActive}
          >
            {bodyComponent}
          </div>
        )}
      </div>
    </>
  );
};

export default AccordionChild;
